@import "../../shared";

.error {
  font-size: $font-size-error;
  color: $color-error;
  width: 250px;
  margin: auto;
  margin-top: $spacing-lg;
  text-align: center;
}
