$color-yellow: #ffcb23;
$color-orange: #ffa63c;
$color-primary: linear-gradient(180deg, $color-yellow 0%, $color-orange 100%);
$color-white: #ffffff;
$color-black: #000000;
$color-gray-dark: #2e3033;
$color-gray-medium: #979899;
$color-gray-light: #bfc3c9;
$color-success: #14aa37;
$color-error: #f53f32;

$shadow-primary: 0px 5px 15px -2px $color-orange;
$shadow-secondary: 0px 5px 15px -2px $color-gray-medium;

$font-size-title: 30px;
$font-size-subtitle: 24px;
$font-size-body: 16px;
$font-size-button: 18px;
$font-size-label: 14px;
$font-size-error: 15px;

$spacing-unit: 8;
$spacing-xxs: #{$spacing-unit * 0.5}px;
$spacing-xs: #{$spacing-unit * 1}px;
$spacing-sm: #{$spacing-unit * 1.5}px;
$spacing-md: #{$spacing-unit * 2}px;
$spacing-lg: #{$spacing-unit * 3}px;
$spacing-xl: #{$spacing-unit * 4}px;
$spacing-xxl: #{$spacing-unit * 5}px;

$error-height: 17px;
