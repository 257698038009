@import "../../shared";

.button {
  cursor: pointer;
  text-align: center;
  font-size: $font-size-button;
  padding: $spacing-md;
  margin: $spacing-md;
  min-width: 250px;
  min-height: 56px;
  font-weight: 500;
  border-radius: $spacing-sm;
  border: none;
  background: $color-primary;
  color: $color-white;
  box-shadow: $shadow-primary;
}

.buttonInnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.buttonText {
  margin-right: $spacing-md;
}
