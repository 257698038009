@import "../../shared";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 250px;
  margin-top: $spacing-md;
}

.label {
  font-size: $font-size-label;
  color: $color-gray-dark;
  margin: 0;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid $color-gray-dark;
}

.input {
  font-size: $font-size-body;
  padding-top: $spacing-xs;
  padding-bottom: $spacing-xs;
  margin-left: $spacing-xxs;
  outline: none;
  border: none;
  width: 100%;
  color: $color-gray-dark;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $color-gray-light;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $color-gray-light;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $color-gray-light;
}

.inputError {
  border-bottom: 1px solid $color-error;
}

.error {
  font-size: $font-size-error;
  color: $color-error;
  padding-top: $spacing-xxs;
  height: $error-height;
  margin: 0;
}
