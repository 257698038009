@import "../../shared";

.qrCodeContainer {
  background: white;
  text-align: center;
  // border: 10px solid #ffc234;
  // border-radius: 10px;
  width: 44%;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: $spacing-xs;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: $color-gray-medium;
  margin-top: $spacing-xs;
  margin-bottom: $spacing-xs;
}

.itemTitle {
  font-size: $font-size-subtitle;
  color: $color-gray-medium;
  margin-bottom: $spacing-xs;
  margin-right: $spacing-xs;
}

.itemValue {
  text-align: right;
  font-size: $font-size-button;
  color: $color-orange;
  margin-bottom: $spacing-xs;
}

.totalAmount {
  font-size: $font-size-subtitle;
  color: $color-gray-dark;
  margin-bottom: $spacing-xs;
}

.textDispaly {
  font-size: $font-size-button;
  color: $color-gray-dark;
  margin-bottom: $spacing-xs;
}

#btnSelected {
  background: red;
}