@import "../../shared";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: $spacing-lg;
  max-width: 400px;
}

.success {
  font-size: $font-size-subtitle;
  color: $color-gray-dark;
  margin-top: $spacing-xxl;
  margin-bottom: $spacing-lg;
  text-align: center;
}
