@import "../../shared";

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $spacing-lg;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: $spacing-lg;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: $color-gray-medium;
  margin-top: $spacing-md;
  margin-bottom: $spacing-md;
}

.itemTitle {
  font-size: $font-size-body;
  color: $color-gray-medium;
  margin-bottom: $spacing-xs;
  margin-right: $spacing-xxl;
}

.itemValue {
  text-align: right;
  font-size: $font-size-button;
  color: $color-orange;
  margin-bottom: $spacing-xs;
}

.totalAmount {
  font-size: $font-size-subtitle;
  color: $color-gray-dark;
  margin-bottom: $spacing-xs;
}
